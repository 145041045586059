@use '@ruf/shell' as ruf;
@use '@ruf/theming' as theming;
@import '@ruf/theming/styles/theming/theming';
@import '@ruf/theming/styles/theming/typography';
@import 'node_modules/@ruf/material/src/sidenav/sidenav.theme';

$typography-config: ruf.recursive-map-merge(
  ruf.ruf-get-default-typography-config(),
  (
    admin-link: ruf.ruf-typography-level(18px, 36px, bold, '"Source Sans Pro", Arial, sans-serif'),
    user-initial: ruf.ruf-typography-level(18px, 36px, 100, '"Source Sans Pro", Arial, sans-serif'),
    link: ruf.ruf-typography-level(16px, 1.5, bold, '"Source Sans Pro", Arial, sans-serif'),
  )
);
@mixin admin-ui-app-theme($theme) {
  @include ruf.ruf-mat-sidenav-theme($theme);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary); // here we grab the primary color palette from the theme
  $emphasis: map-get($theme, emphasis);
  $warn: map-get($theme, warn);

  .app-confirm-block-container .confirm-ui-divider {
    background-color: ruf.ruf-color($background, side);
    border-color: ruf.ruf-color($background, side);
  }

  .gsc-admin-mainbtn {
    background-color: ruf.ruf-color($background, base);
  }
  .admin-user-initials {
    color: ruf.ruf-color($background, base);
  }
  .content-background {
    background: ruf.ruf-color($background, base);
  }
  .admin-ui-circle {
    background-color: ruf.ruf-color($background, contrast-overlay-2);
  }
  .admin-ui-app-canvas {
    background-color: ruf.ruf-color($background, base);
  }
  .admin-ui-home {
    color: ruf.ruf-color($foreground, link);
  }
}

@mixin admin-ui-app-typography($config) {
  .admin-user-initials {
    font-weight: ruf.ruf-font-weight($typography-config, user-initial);
  }
  a.gsc-admin-link {
    font-family: ruf.ruf-font-family($typography-config, admin-link);
    font-size: ruf.ruf-font-size($config, dialog-title);
  }
  p.adminui, dl {
    font-family: ruf.ruf-font-family($typography-config, admin-link);
    font-size: ruf.ruf-font-size($config, dialog-title);
  }
  .admin-ui-breadcrumb {
    .admin-ui-home,
    .advanced-admin {
      font-weight: ruf.ruf-font-weight($config, normal-weight);
      font-family: ruf.ruf-font-family($typography-config, link);
      font-size: ruf.ruf-font-size($typography-config, link);
    }
  }
  .guided-journey-versions {
    font-family: ruf.ruf-font-family($typography-config, link);
    font-size: ruf.ruf-font-size($typography-config, user-initial);
    line-height: ruf.ruf-line-height($typography-config, link);
    font-weight: ruf.ruf-font-weight($typography-config, bold);
  }
  .app-confirm-block-container {
    .confirm-dialog-content {
      font-family: ruf.ruf-font-family($typography-config-obj, snackbar-content);
      font-size: ruf.ruf-font-size($typography-config-obj, dialog-content);
      line-height: ruf.ruf-line-height($typography-config-obj, snackbar-content);
    }
    .confirm-header {
      font-family: ruf.ruf-font-family($typography-config-obj, title);
      font-size: ruf.ruf-font-size($typography-config-obj, link);
      line-height: ruf.ruf-line-height($typography-config-obj, snackbar-content);
      font-weight: ruf.ruf-font-weight($typography-config-obj, snackbar-content);
    }
  }
}
