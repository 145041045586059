 /**
 * Theme configuration file for application.
 * This file acts as a single source of truth, here we can defaine all scss variables, maps 
 * used inside application.
 */

$primary: #4bcd3e;
$accent: #009775;
$emphasis: #ffc845;
$warn: #fd8d62;
$success: #4bcd3e;
$error: #ff1f3e;
$info: #3bcff0;