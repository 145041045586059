@use "@ruf/shell" as ruf;
@use "@ruf/theming" as theming;
@import "@ruf/theming/styles/theming/theming";
@import "@ruf/theming/styles/theming/typography";

$typography-config-obj: ruf.recursive-map-merge(
  ruf.ruf-get-default-typography-config(),
  (
    link: ruf.ruf-typography-level(16px, 1.5, bold, '"Source Sans Pro", Arial, sans-serif'),
    title: ruf.ruf-typography-level(18px, 27px, bold, '"Titillium Web"'),
    preview-heading: ruf.ruf-typography-level(22px, 1.27, bold, '"Titillium Web"'),
    info-icon: ruf.ruf-typography-level(50px, 1.27, bold, '"Titillium Web"'),
    dialog-content: ruf.ruf-typography-level(14px, 1.57, bold, '"Source Sans Pro", Arial, sans-serif'),
    snackbar-content: ruf.ruf-typography-level(14px, 1.71, bold, '"Source Sans Pro", Arial, sans-serif'),
  )
);
@mixin home-layout-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  .home-confirm-block-container,
  .preview-confirm-block-container {
    .confirm-ui-divider {
      background-color: ruf.ruf-color($background, side);
      border-color: ruf.ruf-color($background, side);
    }
  }
  .admin-ui-home-layout {
    background-color: ruf.ruf-color($background, base);
  }
  .home-form-comp-div,
  .preview-form-comp-div,
  .preview-tree-comp-div,
  .home-tree-comp-div {
    border-color: ruf.ruf-color(theming.$ruf-fis-light-neutral-1, 200);
  }
  .admin-ui-home {
    color: ruf.ruf-color($foreground, link);
  }
  .ruf-dialog-header.mat-dialog-title {
    border-color: ruf.ruf-color(theming.$ruf-fis-light-neutral-1, 300);
  }
  .admin-ui-preview-layout {
    .admin-ui-red-color {
      background-color: ruf.ruf-color(theming.$ruf-fis-error, 50);
    }

    .admin-ui-green-color {
      background-color: ruf.ruf-color(theming.$ruf-fis-primary, 50);
    }

    .admin-ui-yellow-color {
      // Below is the nearest color in Ruf palette for #ffeec7(from zeplin)
      background-color: ruf.ruf-color(theming.$ruf-fis-emphasis1, 100);
    }

    .admin-ui-parent-node-color {
      background-color: ruf.ruf-color(theming.$ruf-fis-info, 50);
    }
  }
  .expanded-breadcrumb-container {
    .mat-icon.fis-icon-chevron:hover {
      background-color: ruf.ruf-color(theming.$ruf-fis-black, 100);
    }
    .mat-icon-rotate {
      background-color: ruf.ruf-color(theming.$ruf-fis-black, 100);
    }
  }
  .expanded-breadcrumb-dialog {
    .ruf-dialog-container {
      border-color: ruf.ruf-color($foreground, border);
    }
  }
}
@mixin home-layout-typography($config) {
  .admin-ui-home-layout,
  .admin-ui-preview-layout {
    .home-guided-journey,
    .preview-guided-journey {
      font-family: ruf.ruf-font-family($typography-config-obj, title);
      font-size: ruf.ruf-font-size($typography-config-obj, title);
      line-height: ruf.ruf-line-height($typography-config-obj, title);
      font-weight: ruf.ruf-font-weight($config, bold);
    }
    .guided-journey-versions,
    .preview-guided-journey-versions {
      font-family: ruf.ruf-font-family($typography-config-obj, link);
      font-size: ruf.ruf-font-size($typography-config-obj, dialog-content);
      line-height: ruf.ruf-line-height($typography-config-obj, title);
      font-weight: ruf.ruf-font-weight($typography-config, bold);
    }
    .preview-title {
      font-family: ruf.ruf-font-family($typography-config-obj, preview-heading);
      font-size: ruf.ruf-font-size($typography-config-obj, preview-heading);
      font-weight: ruf.ruf-font-weight($config, bold);
    }
    .preview-content {
      font-family: ruf.ruf-font-family($typography-config-obj, link);
      font-size: ruf.ruf-font-size($config, subheading-2);
      font-weight: ruf.ruf-font-weight($config, subheading-2);
      line-height: ruf.ruf-line-height($typography-config-obj, link);
    }
    .info-icon {
      font-size: ruf.ruf-font-size($typography-config-obj, info-icon) !important;
    }
    .dialog-content {
      line-height: ruf.ruf-line-height($typography-config-obj, dialog-content);
    }
  }

  .admin-ui-snackbar {
    .snackbar-text {
      font-family: ruf.ruf-font-family($typography-config-obj, snackbar-content);
      font-size: ruf.ruf-font-size($typography-config-obj, snackbar-content);
      line-height: ruf.ruf-line-height($typography-config-obj, snackbar-content);
      font-weight: ruf.ruf-font-weight($typography-config-obj, snackbar-content);
    }
  }

  .home-confirm-block-container,
  .preview-confirm-block-container {
    .confirm-dialog-content {
      font-family: ruf.ruf-font-family($typography-config-obj, snackbar-content);
      font-size: ruf.ruf-font-size($typography-config-obj, dialog-content);
      line-height: ruf.ruf-line-height($typography-config-obj, snackbar-content);
    }
    .confirm-header {
      font-family: ruf.ruf-font-family($typography-config-obj, title);
      font-size: ruf.ruf-font-size($typography-config-obj, link);
      line-height: ruf.ruf-line-height($typography-config-obj, snackbar-content);
      font-weight: ruf.ruf-font-weight($typography-config-obj, snackbar-content);
    }
  }
}
