@use '@ruf/theming' as theming;

/* Importing the prebuilt color theme called "fis-default" */
@function ruf-get-overridden-config($theme, $factor) {
  $accent: map-get($theme, accent);
  $foreground: map-get($theme, foreground);
  @return (
    ruf-shell-theme-config: (
      ruf-dynamic-sidemenu: (
        sidemenu-item: (
          light-background: (
            parent-item-hover-bg-color: map-get(theming.$ruf-fis-light-neutral-1, 50),
          ),
        ),
      ),
    )
  );
}
// Created new file and imported below because of bug in ruf.
// TODO: Whenever bug will be fixed will remove below import and use "@import '~@ruf/prebuilt-themes/fis-default"
@import './ruf-default-prebuilt-theme';
//@import '~@ruf/prebuilt-themes/fis-default';
//@import '~@ruf/prebuilt-themes/fis-default-css-vars';

@import './all-theme';
@import './theme-config.scss';

// Importing the RUF shell structural styles
@import '@ruf/shell/styles/index';

@include app-all-theme($theme);
