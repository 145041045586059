// A color theme does not affect the structural styles of components;
// it only affects colors.
// This color theme also provides colors to material 2 components.

@use 'sass:meta';
// Include material's core styles: they provide styles used by multiple
// components through the mat.core mixin included below.
@use '@angular/material' as mat;

// Import all of RUF's theme files
// Importing this file ends up also importing the theming.scss and palette.scss files.
// Dependency graph:
// all-theme -> component theme file -> theming -> palette
@use '@ruf/shell' as ruf;


// Define a color theme .
$primary: ruf.ruf-palette(ruf.$ruf-fis-primary) !default;
$accent:  ruf.ruf-palette(ruf.$ruf-fis-accent1) !default;
$emphasis: ruf.ruf-palette(ruf.$ruf-fis-emphasis1) !default;
$warn: ruf.ruf-palette(ruf.$ruf-fis-orange) !default;

$accent2: ruf.ruf-palette(ruf.$ruf-fis-accent2) !default;
$emphasis2: ruf.ruf-palette(ruf.$ruf-fis-emphasis2) !default;

$success: ruf.ruf-palette(ruf.$ruf-fis-success) !default;
$error: ruf.ruf-palette(ruf.$ruf-fis-error) !default;
$info: ruf.ruf-palette(ruf.$ruf-fis-info) !default;

$factor : 1;
$overridden-config-store: ();

// Can be used to override foreground & background palette.
$ruf-override-light-theme-foreground: () !default;
$ruf-override-light-theme-background: () !default;

$foreground: ruf.recursive-map-merge(
              ruf.ruf-get-default-light-theme-foreground-config(),
              $ruf-override-light-theme-foreground
            ) !default;
$background: ruf.recursive-map-merge(
              ruf.ruf-get-default-light-theme-background-config(),
              $ruf-override-light-theme-background
            ) !default;

$_default-density: 0;

// @debug "#{$background}";

$theme: ruf.ruf-light-theme((
  color: (
    primary: $primary,
    accent: $accent,
    emphasis: $emphasis,
    warn: $warn,
    foreground: $foreground,
    background: $background,
    success: $success,
    error: $error,
    info: $info
  ),
  density: $_default-density
));

// If ruf-get-overridden-config present then get the config and store it in local variable
@if meta.function-exists(ruf-get-overridden-config) {
  $overridden-config-store: ruf-get-overridden-config($theme, $factor);
}

$default-config-store: ruf.ruf-get-default-config-store($theme, $factor);

/* Density Configuration */
$density-config: ruf.ruf-get-config($default-config-store, $overridden-config-store, density);

$density-interval: ruf.ruf-map-get($density-config, interval, ruf.$density-interval);
$density-scale: ruf.ruf-map-get($density-config, scale, $_default-density);

$typography-config: ruf.ruf-get-config($default-config-store, $overridden-config-store, common, typography) !default;
// this is component based configuration where default typography config does not work
$overridden-material-typography-config: ruf.ruf-get-config($default-config-store, $overridden-config-store, material, typography);
// this is component based configuration for shell components where default typography config does not work
$overridden-shell-typography-config: ruf.ruf-get-config($default-config-store, $overridden-config-store, shell, typography);

$material-structural-config: ruf.ruf-get-config($default-config-store, $overridden-config-store, material, structural);

$shell-structural-config: ruf.ruf-get-config($default-config-store, $overridden-config-store, shell, structural);

$shell-theme-config: ruf.ruf-get-config($default-config-store, $overridden-config-store, shell, theme);
$material-theme-config: ruf.ruf-get-config($default-config-store, $overridden-config-store, material, theme);

// widgets
$widgets-theme-config: ruf.ruf-get-config($default-config-store, $overridden-config-store, widgets, theme);
$widgets-structural-config: ruf.ruf-get-config($default-config-store, $overridden-config-store, widgets, structural);

// ngx-datatable
$ruf-ngx-datatable-theme-config: ruf.ruf-get-config($default-config-store, $overridden-config-store, ngx-datatable, theme);
$ruf-ngx-datatable-structural-config: ruf.ruf-get-config($default-config-store, $overridden-config-store, ngx-datatable, structural);
$ruf-ngx-datatable-typography-config: ruf.ruf-get-config($default-config-store, $overridden-config-store, ngx-datatable, typography);

// Include material core (overlay, ripples...)
@include mat.core( ruf.ruf-typography-config($typography-config));

// Include all RUF color theme styles for the components.
@include ruf.ruf-theme(
  $theme,
  $typography-config,
  $shell-theme-config,
  $overridden-typography-config: $overridden-shell-typography-config
);

// Here you either include each mixin for material 2 components
// that you have imported above or just include 'angular-material-theme($theme)'
// if you imported material 2's all-theme file
// @include mat.sidenav-theme($theme);
// @include mat.radio-theme($theme);
@include mat.all-component-themes($theme);

// Include @ruf/material theme and structure mixins
@include ruf.ruf-material-structure($structural-config: $material-structural-config, $density: $density-scale);
@include ruf.ruf-material-theme($theme, $typography-config, $theme-config: $material-theme-config,
$overridden-typography-config: $overridden-material-typography-config);

// Include mixin to override structural styling.
@include ruf.ruf-shell-override($structural-config: $shell-structural-config);
