@use '@ruf/shell' as ruf;
@use '@ruf/theming' as theming;
@import '@ruf/theming/styles/theming/theming';
@import '@ruf/theming/styles/theming/typography';

$migrate-data-typo-config: ruf.recursive-map-merge(
  ruf.ruf-get-default-typography-config(),
  (
    title: ruf.ruf-typography-level(20px, 31px, bold, '"Titillium Web"'),
    field-label:
      ruf.ruf-typography-level(
        14px,
        1.29,
        bold,
        '"Source Sans Pro", Arial, sans-serif'
      ),
    file-icon: ruf.ruf-typography-level(35px, 1.27, bold, '"Titillium Web"'),
    file-name: ruf.ruf-typography-level(14px, 21px, bold, '"Titillium Web"'),
  )
);

@mixin migrate-data-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .digital-platform-progress-tile {
    background-color: ruf.ruf-color(theming.$ruf-fis-light-neutral-1, 50);
  }
  .migrate-form-validation-errors {
    color: $error;
  }
  .admin-ui-file-error {
    color: $error;
  }
  .form-required-input {
    color: $error;
  }
}

@mixin migrate-data-typography($config) {
  .admin-ui-migrate-data {
    .migrate-data {
      font-weight: ruf.ruf-font-weight($migrate-data-typo-config, title);
      font-family: ruf.ruf-font-family($migrate-data-typo-config, title);
      font-size: ruf.ruf-font-size($migrate-data-typo-config, title);
      line-height: ruf.ruf-line-height($migrate-data-typo-config, title);
    }
    .field-label {
      font-weight: ruf.ruf-font-weight($migrate-data-typo-config, field-label);
      font-family: ruf.ruf-font-family($migrate-data-typo-config, field-label);
      font-size: ruf.ruf-font-size($migrate-data-typo-config, field-label);
      line-height: ruf.ruf-line-height($migrate-data-typo-config, field-label);
    }
    .file-icon {
      font-size: ruf.ruf-font-size(
        $migrate-data-typo-config,
        file-icon
      ) !important;
    }
    .file-name {
      font-weight: ruf.ruf-font-weight($migrate-data-typo-config, file-name);
      font-family: ruf.ruf-font-family($migrate-data-typo-config, file-name);
      font-size: ruf.ruf-font-size($migrate-data-typo-config, file-name);
      line-height: ruf.ruf-line-height($migrate-data-typo-config, file-name);
    }
  }
}
