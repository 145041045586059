@use '@ruf/shell' as ruf;

@mixin webhook-datable-typography($config) {
  .webhook-table {

    .mat-cell,
    th {
      font-size: ruf.ruf-font-size($config, caption-2) !important;
    }
  }
}