@use '@ruf/shell' as ruf;

@mixin webhook-view-theme($theme) {
  .webhook-view {
    table {
      tr.mat-header-row {
        background-color: ruf.ruf-color($background, card-canvas);
      }
    }
  }
}

@mixin webhook-view-typography($config) {
  .webhook-view {
    table {
      th,
      td {
        font-size: ruf.ruf-font-size($config, subheading-1) !important;
      }
    }
  }
}